@import '../../../../styles/variables.scss';
$mainText: #514b4d;
$text_000: #000000;
$text_7e7e7e: #7e7e7e;
$text_adadad: #adadad;
$color_white: #fff;
$text_231F20: #231f20;

.request-ship-2-buyer {
  position: relative;
  max-width: 1740px;
  min-width: 1260px;
  height: 100%;
  padding-bottom: 16px;

  &__btn-back {
    z-index: 100;
    position: absolute;
    top: 0;
    left: -44px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: $color_white;
    border-radius: 100%;
    transition: all 0.3s linear;
    filter: drop-shadow(1px 1px 8px rgba(0, 0, 0, 0.12));

    svg {
      path {
        transition: all 0.3s linear;
      }
    }

    &:hover {
      background: $primary_color;
    }

    &:hover path {
      fill: #fff;
    }
  }

  .edit-detail-buyer {
    display: flex;
    align-items: center;
    gap: 24px;

    button {
      background: unset;
      border-radius: 50%;
      box-shadow: 1px 1px 8px 0px #0000001f;
      width: 32px;
      height: 32px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        rect {
          stroke: #fff;
        }
      }

      &:hover {
        svg {
          fill: $primary_color;

          path {
            stroke: #fff;
          }
        }
      }
    }
  }

  &__modal-edit-address {
    width: 630px !important;
    top: 24px !important;

    .ant-modal {
      &-header {
        padding: 0;
        margin-bottom: 32px;
      }

      &-title {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        color: $mainText;
        width: 480px;
        margin: 0 auto;
      }

      &-body {
        width: 480px;
        margin: 0 auto;
      }

      &-content {
        padding: 40px;
        border-radius: 20px;

        .ant-modal-close {
          width: 20px;
          height: 20px;

          .anticon svg {
            width: 11.5px;
            height: 11.5px;
            fill: $mainText;
          }
        }

        input {
          outline: none;
          appearance: none;

          &[title] {
            pointer-events: none;
          }

          &:-webkit-autofill {
            background-color: transparent !important;
            box-shadow: 0 0 0px 1000px white inset !important;
            -webkit-text-fill-color: #000 !important;
          }

          &:-webkit-autofill,
          &:-internal-autofill-selected {
            appearance: none !important;
          }
        }

        .ant {
          &-form {
            &-item {
              margin-bottom: 16px;

              &-label {
                label {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  color: $mainText;
                }
              }

              input {
                width: 100%;
                height: 40px;
                border-radius: 8px;

                &[type='number'] {
                  &::-webkit-inner-spin-button,
                  &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                  }
                }
              }

              .ant-select-selector {
                border: none;
              }

              &-required,
              .ant-select-selection-item {
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: $mainText;

                &::before {
                  display: none !important;
                }
              }
            }
          }

          &-select {
            &-item {
              &-option {
                display: flex;
                align-items: center;
              }
            }
          }
        }
      }

      &-footer {
        width: 480px;
        margin: 60px auto 0;
        padding: 0;
        display: flex;
        align-items: center;
        gap: 18px;

        button {
          width: 184px;
          height: 44px;
        }
      }
    }
  }

  &-container {
    height: 100%;
    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      color: $text_231F20;
    }

    .list-info {
      display: flex;
      flex-direction: column;
      gap: 6px;
      padding-left: 16px;

      .label-box {
        border: 1px solid $primary_color;
        width: 92px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          color: $mainText;
        }
      }

      &__address {
        .info-item {
          position: relative;
          &__left {
            width: 96px;
          }
          &__editTracking {
            width: 32px;
            height: 32px;
            border-radius: 48px;
            box-shadow: 1px 1px 8px 0px #0000001f;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            right: 0;

            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }

      &__product {
        padding-left: 0;
        gap: 4px;

        .info-item {
          &__left {
            width: 160px;
          }
        }
      }
    }

    .box {
      background-color: $color_white;
      padding: 14px;
      border-radius: 8px;
      box-shadow: 2px 2px 4px 2px #6564641f;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .title {
        background-color: $color_20;
        border-radius: 21px;
        padding: 4px 8px 4px 16px;
        min-height: 32px;
        display: flex;
        align-items: center;

        h3 {
          font-size: 14px;
          font-weight: 600;
          line-height: 22px;
          color: $primary_color;
          margin-top: 2px;
          margin-bottom: unset;
        }
      }
    }

    .content-ship {
      display: flex;
      gap: 12px;
      height: calc(100% - 60px);

      .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 12px;
        min-width: 860px;
        max-width: 1290px;

        .product-info {
          height: 212px;
          flex-shrink: 0;
          z-index: 0;

          .box__wrap {
            display: flex;
            gap: 16px;
            padding-left: 16px;

            .product-info__img {
              width: 118px;
              height: 118px;
              border-radius: 6px;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 6px;
              }
            }
          }
        }

        &__detail-ship {
          height: 212px;
          display: flex;
          gap: 12px;
          width: 100%;

          .shipping-info {
            width: 50%;
            flex-shrink: 0;
            z-index: 0;

            .list-info {
              height: 100%;
              gap: 10px;
              .info-item {
                &:nth-child(3) {
                  flex: 1;
                  max-height: 22px;
                }
              }
            }
          }

          .address {
            z-index: 0;
            width: 50%;
            flex: 1;

            .address-confirm {
              &.list-info {
                height: 100%;
                gap: 10px !important;
              }
            }
          }

          .box-size__info {
            width: 50%;
            flex-shrink: 0;
          }

          .memo {
            width: 50%;
            z-index: 0;

            &__wrap {
              display: flex;
              flex-direction: column;
              gap: 8px;
            }

            &__content {
              display: flex;
              gap: 8px;

              .avt {
                width: 40px;
                height: 40px;
                flex-shrink: 0;

                img {
                  width: 100%;
                  height: 100%;
                }
              }

              .edit {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 8px;

                .custom-textarea-container {
                  position: relative;
                  width: 100%;
                }

                .custom-placeholder {
                  position: absolute;
                  top: 8px;
                  left: 16px;
                  right: 16px;
                  color: $text_7e7e7e;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  pointer-events: none;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: pre-wrap;
                  z-index: 3;
                }

                .custom-textarea {
                  width: 100%;
                  height: 66px;
                  padding: 8px 16px;
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  border: 1px solid #adadad;
                  border-radius: 8px;
                  resize: none;
                  color: $mainText;

                  &.ant-input-disabled {
                    background: #f2f2f2;
                    border: none;
                    cursor: context-menu;
                    border: 1px solid #adadad;
                  }
                }
              }

              textarea {
                height: 66px;
                resize: none;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                color: $mainText;
                padding: 8px 16px;
                border-radius: 8px;

                &::placeholder {
                  color: $mainText;
                }

                &.ant-input-disabled {
                  background: #f2f2f2;
                  border: none;
                  cursor: context-menu;
                }
              }
            }
          }
        }

        .row-size {
          height: 212px;
        }

        .size-pack {
          &.list-info {
            gap: 12px;
          }
          .item {
            display: flex;
            align-items: center;

            &__label {
              font-size: 12px;
              font-weight: 500;
              line-height: 18px;
              color: $text_7e7e7e;
              margin-bottom: 0px;
            }

            .size {
              display: flex;
              gap: 20px;
              align-items: center;
              margin-left: 24px;

              &__detail {
                display: flex;
                gap: 8px;
                align-items: center;

                span {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  color: $text_7e7e7e;
                }

                .number {
                  width: 62px;
                  height: 34px;
                  border-radius: 4px;
                  background-color: #f2f2f2;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  span {
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 18px;
                    color: $text_7e7e7e;
                  }
                }
              }

              &.weight {
                margin-left: 42px;

                .size__detail {
                  gap: 24px;
                }
              }
            }

            .hs-price {
              margin-left: 67px;
              .ant-input {
                width: 62px;
                height: 34px;
                border-radius: 4px;
                border: 1px solid $text_adadad;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                color: $mainText;
                text-align: center;

                &[type='number'] {
                  &::-webkit-inner-spin-button,
                  &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                  }
                }
              }
            }

            .hs-code {
              display: flex;
              gap: 8px;
              align-items: center;
              margin-left: 42px;

              span {
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                color: $text_7e7e7e;
              }

              .ant-input {
                width: 114px;
                height: 34px;
                border-radius: 4px;
                border: 1px solid $text_adadad;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                color: $mainText;
                text-align: center;

                &[type='number'] {
                  &::-webkit-inner-spin-button,
                  &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                  }
                }
              }

              .ioss {
                width: 114px;
                height: 34px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #f2f2f2;

                span {
                  font-size: 12px;
                  font-weight: 500;
                  line-height: 18px;
                  color: $text_7e7e7e;
                }
              }
            }

            .pin {
              margin-left: 74px;

              .ant-custom-select {
                width: 469px;
                height: 34px;

                .ant-select-selector {
                  border-radius: 4px !important;
                  .ant-select-selection-item {
                    svg {
                      display: none;
                    }
                    .option-pin {
                      width: 100%;

                      .text {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: $mainText;
                      }
                    }
                  }
                }

                .ant-select-dropdown {
                  .ant-select-item-option {
                    padding: 0 10px 0 12px;
                  }
                  .ant-select-item-option-content {
                    .option-pin {
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                      height: 34px;
                      padding: 8px 0;

                      .text {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 18px;
                        color: $mainText;
                        font-family: 'Noto Sans JP', sans-serif !important;
                        margin-bottom: 3px;
                      }

                      svg {
                        flex-shrink: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .right {
        width: 412px;
        flex-shrink: 0;
        padding: 46px 14px;
        box-shadow: 2px 2px 4px 2px #6564641f;
        background-color: $color_white;
        border-radius: 8px;

        .bill {
          display: flex;
          flex-direction: column;
          height: 100%;
          justify-content: space-between;

          &__info {
            display: flex;
            flex-direction: column;
            gap: 12px;

            &__item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 0 16px;

              .label,
              .value {
                font-size: 14px;
                font-weight: 600;
                line-height: 22px;
                color: $mainText;
              }
            }
          }

          &__total {
            border-top: 1px solid $text_7e7e7e;
            padding: 24px 16px 0;

            &__item {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .label,
              .value {
                font-weight: 600;
                color: $mainText;
              }

              .label {
                font-size: 16px;
                line-height: 16px;
              }

              .value {
                font-size: 24px;
                line-height: 24px;
              }
            }
          }

          &__confirm {
            display: flex;
            flex-direction: column;
            gap: 12px;

            &__item {
              display: flex;
              flex-direction: column;
              padding: 0 16px;
              gap: 6px;

              .title {
                font-weight: 700;
                font-size: 14px;
                line-height: 22px;
                color: $mainText;
              }

              .carrier {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 16px;

                &-left {
                  display: flex;
                  gap: 12px;
                  align-items: center;

                  &__info {
                    display: flex;
                    flex-direction: column;

                    .name {
                      font-weight: 600;
                      font-size: 14px;
                      line-height: 22px;
                      color: $mainText;
                    }

                    .day {
                      font-weight: 500;
                      font-size: 12px;
                      line-height: 18px;
                      color: $mainText;
                    }
                  }
                }

                &-right {
                  .price {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22px;
                    color: $mainText;
                  }
                }
              }

              .insurance {
                display: flex;
                justify-content: space-between;
                padding: 0 16px;

                .label {
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 18px;
                  color: $mainText;
                }

                .value {
                  font-weight: 600;
                  font-size: 14px;
                  line-height: 22px;
                  color: $mainText;
                }
              }
            }
          }
        }
      }
    }

    .content-partitions {
      display: flex;
      flex-direction: column;
      gap: 12px;

      &.info {
        flex-direction: row;
        gap: 12px;
        margin-top: 20px;
      }

      &_img {
        width: 96px;
        height: 96px;
        border-radius: 6px;

        .ant-image {
          img {
            border-radius: 6px;
          }
        }
      }

      &_right {
        display: flex;
        flex-direction: column;
        gap: 4px;

        .name-item {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: $mainText;
          width: 292px;
          height: unset;
        }
      }

      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        .link-user {
          color: $mainText;

          &:hover {
            text-decoration: underline;
          }
        }

        span {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          color: $mainText;
        }

        .colon {
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          color: $mainText;
        }

        &-address {
          display: flex;
          justify-content: space-between;
          width: 96px;
          margin-right: 8px;
          font-size: 14px;
          font-weight: 500;
          color: #7e7e7e;
          flex-shrink: 0;

          &.top {
            width: 111px;

            .text {
              font-size: 14px;
              font-weight: 500;
              line-height: 22px;
              color: #7e7e7e;
              height: unset;
            }
          }
        }

        .unit {
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
        }

        &-size {
          display: flex;
          flex-direction: row;
          gap: 32px;
          font-size: 14px;
          font-weight: 500;
          color: $text_7e7e7e;
          width: 100%;

          span {
            display: inline-block;
          }

          &-item {
            display: flex;
            justify-content: space-between;
            margin-right: 8px;

            .colon {
              margin-left: 8px;
            }

            &-value {
              font-size: 16px;
              font-weight: 600;
              color: $mainText;
            }
          }
        }
      }

      .carrier {
        display: flex;
        gap: 34px;

        &-item {
          width: 236px;
          height: 106px;
          padding: 9px;
          gap: 8px;
          display: flex;
          align-items: center;
          border-radius: 6px;
          border: 2px solid transparent;
          background: #fff;
          box-shadow: -2px 2px 8px 0px rgba(0, 0, 0, 0.12);
          cursor: pointer;
          transition: all 0.3s linear;
          position: relative;

          &:hover {
            background: $color_40;
          }

          &.active {
            background: $color_10;
            border: 2px solid $color_60;

            &.disabled {
              background-color: unset;
              border: unset;
            }
          }

          .space {
            width: 24px;
            flex-shrink: 0;
          }

          .name,
          .price {
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
          }

          .name {
            color: $text_231F20;
          }

          .price {
            color: $mainText;
          }

          &-information {
            position: absolute;
            top: 8px;
            right: 8px;
            z-index: 3;

            &:hover {
              cursor: pointer;
            }
          }

          .ant-radio-inner {
            width: 20px;
            height: 20px;
            border-color: $mainText !important;
            border-width: 2px;
            background-color: #fff !important;

            &::after {
              background-color: $mainText;
              transform: scale(0.6) !important;
            }
          }

          .ant-radio-checked {
            .ant-radio-inner {
              border-color: $primary_color !important;

              &::after {
                background-color: $primary_color;
              }
            }
          }
        }
      }

      .insurance {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-left: 9px;
        flex-direction: row;
        gap: 8px;

        .ant-checkbox {
          &-inner {
            border-radius: 2px;
            width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            &::after {
              content: '';
              position: absolute;
              width: 8.33px !important;
              height: 5.25px !important;
              left: 2px;
              top: 3px;
              transform: rotate(320deg) scaleX(-1);
            }
          }
        }

        .ant-checkbox + span {
          display: none;
        }

        label {
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          color: $mainText;
        }

        &_item {
          &_price,
          .colon {
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            color: $mainText;
          }

          &_checkbox {
            margin-left: unset !important;
            .ant-checkbox {
              &-inner {
                border-radius: 2px;
              }
            }
          }
        }

        &_wp {
          display: flex;
          align-items: center;
          gap: 8px;
        }
      }
    }
  }

  &-button-wrap {
    display: flex;
    gap: 24px;
    justify-content: center;
    margin-top: 61px;

    .button-cancel {
      border: 1px solid $text_7e7e7e;
    }
  }

  .btn-back {
    background-color: #fff;
    padding: 0;
  }

  .loading {
    z-index: 1;
    width: 100%;
    height: 100%;
    justify-content: center;
    top: 0;
    background: #fff;
    opacity: 0.5;
    position: absolute;

    .ant-spin {
      top: 50%;
      left: 50%;
    }
  }
}

.modal-confirm-ship-by-date {
  .ant-modal-content {
    padding: 24px 32px;
    max-width: 464px;
    max-height: 212px;

    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: center;

      .content {
        display: flex;
        gap: 8px;

        svg {
          flex-shrink: 0;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: $mainText;
        }
      }
    }
  }

  &__cancel {
    background: #dcdcdc;
    border: 1px solid $text_7e7e7e !important;
    padding: 8px 16px;
    border-radius: 2px;
    width: 148px !important;
    height: 44px !important;

    span {
      color: #231f20;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    &.custom-ant-button:hover {
      background: #f2f2f2 !important;
      border-color: #9d9d9d !important;
    }
  }
}
