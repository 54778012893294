@import '../../../../../styles/variables.scss';
$mainText: #514b4d;
$text_000: #000000;
$text_7e7e7e: #7e7e7e;
$text_adadad: #adadad;
$color_white: #fff;
$text_231F20: #231f20;

.box-size__info {
  flex-shrink: 0;
  height: 212px;

  &.box {
    background-color: $color_white;
    padding: 14px;
    border-radius: 8px;
    box-shadow: 2px 2px 4px 2px #6564641f;
    display: flex;
    flex-direction: column;
    gap: 12px;

    .title {
      background-color: $color_20;
      border-radius: 21px;
      padding: 4px 8px 4px 16px;
      min-height: 32px;
      display: flex;
      align-items: center;

      h3 {
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        color: $primary_color;
        margin-top: 2px;
        margin-bottom: unset;
      }
    }
  }

  .list-info {
    padding: 0 16px;
  }

  .size-info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 4px;

      &-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: $mainText;
      }

      &-value {
        display: flex;
        align-items: center;
        gap: 20px;
        padding-left: 16px;

        .detail {
          display: flex;
          align-items: center;
          gap: 8px;

          &-weight {
            padding-left: 20px;
          }

          span {
            font-size: 12px;
            font-weight: 500;
            line-height: 18px;
            color: $text_7e7e7e;
            flex-shrink: 0;
          }

          .number {
            width: 62px;
            height: 34px;
            border-radius: 4px;
            background-color: #f2f2f2;
            display: flex;
            justify-content: center;
            align-items: center;

            span {
              font-size: 12px;
              font-weight: 500;
              line-height: 18px;
              color: $text_7e7e7e;
            }
          }
        }
      }
    }
  }
}
