@import '../../styles/variables.scss';

.layout-detail {
  padding: 16px 52px 0;
  background-color: $white_2;
  margin: 0;
  overflow: auto;

  &.first-setting-layout {
    padding: 16px 52px;
  }
}
