$primaryColor: #ef6e22;
$primaryColor-bright: #fde4d2;
$mainText: #514b4d;
$text_000: #000000;
$text_7e7e7e: #7e7e7e;
$text_adadad: #adadad;
$color_white: #fff;
$text_231F20: #231f20;

.info-item {
  display: flex;
  align-items: start;
  gap: 8px;

  &__left {
    width: 69px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .label {
      color: $text_7e7e7e;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      margin-bottom: unset;
      display: flex;
      align-items: center;
      gap: 4px;
      min-height: 22px;

      .icon {
        cursor: pointer;
        z-index: 5;
      }
    }

    span {
      color: $mainText;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
    }
  }

  &__right {
    display: flex;
    color: $mainText;
    flex: 1;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    line-break: anywhere;
    z-index: 10;
    max-height: 48px;
    width: 100%;

    &.text-1-line {
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &.underline {
      text-decoration: underline;

      a {
        color: $mainText;
        font-weight: 600;

        .ant-form-item {
          margin: unset;

          .ant-form-item-control-input {
            min-height: 24px;
          }

          input {
            max-width: 166px;
            height: 24px;
            max-height: 24px;
            padding: 8px;
            border-radius: 4px;
            border: 1px solid $primaryColor;
            background-color: transparent;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .group-action {
          width: 52px;
          display: flex;
          justify-content: space-between;
          position: relative;

          span {
            position: relative;
            z-index: 1;
            cursor: pointer;

            &:hover {
              z-index: 10;
            }
          }
        }
      }
    }
  }

  .info-item__editTracking {
    cursor: pointer;
    z-index: 10;
  }
}
