@import './font.scss';

$font-primary: 'Market Sans', 'Noto Sans JP', sans-serif;

$text_title_color: #231f20;
$text_title_sub_color: #514b4d;
$color_cancel: #9d9d9d;
$color_sub_cancel: #7e7e7e;
$bg_cancel: #9d9d9d;

///////// ---- Color ------ /////////
$primary_color: #ef6e22;
$color_90: #f47920;
$color_80: #f6944d;
$color_60: #f8af79;
$color_40: #fbc9a6;
$color_20: #fde4d2;
$color_10: #fef2e9;
$border_avatar: #fbc9a6;
$black-2: #514b4d;
$black_3: #7e7e7e;
$main_gradient: linear-gradient(180deg, #e65925 -0.03%, #e96024 18.96%, $color_90 99.95%);
$white_2: #f7f7f7;
$require_color: #fd161a;
///////// ---- Color ------ /////////

$bg_second: linear-gradient(180deg, #e65925 -0.03%, #e96024 18.96%, $color_90 99.95%);
