@import '../../styles/variables.scss';
.header {
  background: $color_90 !important;
  align-items: center;
  height: 58px;
  padding: 0;

  .ant-row {
    flex: 1 1 auto;
  }

  .menu-profile.menu-setting {
    width: 58px;
    margin-right: 8px;
  }

  &-logo {
    height: 100%;
    gap: 12px;
    align-items: center;
    justify-content: start !important;

    span {
      margin-left: 24px;
      height: 100%;
      display: flex;
      align-items: center;
      // width: 240px;
    }

    h2 {
      font-size: 20px;
      font-weight: 600;
      line-height: 32px;
      color: #fff;
      margin: 0;
    }

    .logo {
      cursor: pointer;
    }
  }

  &-expired {
    &-list {
      display: flex;
      flex-direction: column;
      color: #fff;
      justify-content: space-between;
      line-height: normal;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: unset;
        max-width: 500px;
        display: inline-block;
        font-size: 12px;
        margin-left: unset;
      }
    }
  }

  &-right {
    align-items: center;
    justify-content: end !important;

    &.hidden {
      display: none;
    }

    &-support {
      width: 95px;
      height: 28px;
      border-radius: 4px;
      border: 2px solid #ffffff;
      background-color: $color_90;
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      margin-right: 36px;
      font-family: 'Market Sans', 'Noto Sans JP', sans-serif !important;
    }

    .menu-page {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 16px;
      margin-right: 2px;

      &-item {
        display: flex;
        align-items: center;
        height: 32px;
        gap: 8px;
        cursor: pointer;
        width: 96px;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        justify-content: center;
        padding-top: 2px;
        user-select: none;

        &:hover,
        &.active {
          background: #ffffff26;
          border-radius: 5px;
          border: 1px solid $color_60;
        }
      }

      &-icon,
      .menu-icon-small {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    &__item {
      display: flex;
      align-items: start;
      cursor: pointer;
      margin-right: 2px;
      width: 58px;
      gap: 3.5px;

      &.task {
        gap: 5.25px;

        img {
          width: 24px;
          height: 24px;

          &.icon-active {
            width: 24px;
            height: 24px;
          }
        }
      }
      &.notification {
        gap: 4.5px;
        width: 58px;
      }

      &.setting {
        min-width: 28px;
        max-width: 28px;
      }

      svg {
        cursor: pointer;
        width: 24px;
        height: 24px;
      }

      &-number {
        position: relative;
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        color: #fff;
        top: -4px;
        min-width: 16px;
        max-width: 24px;
        height: 14px;

        &.notification-number {
          top: -3px;
        }
      }
    }
  }
}

.custom-avatar {
  border: 2.5px solid $border_avatar;
}

.setting-drop {
  display: flex;
  width: 280px;
  padding: 16px 8px;
  flex-direction: column;

  &-text-menu {
    color: #514b4d;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    display: flex;
    height: 46px;
    align-items: center;
    align-self: stretch;
    width: 100% !important;
  }
}

.dropdown-menu-biz {
  height: 80vh;
  overflow-y: auto;
  width: 58px;

  .biz-drop {
    position: fixed !important;
    top: 50px !important;
    right: 156px !important;
    width: 400px;
    padding: 0 !important;
    overflow-y: scroll;
    max-height: calc(100vh - 48px);

    li {
      padding: 16px !important;
      height: 96px;
      border-bottom: 0.5px solid #dcdcdc;
      border-radius: unset !important;

      &:hover {
        cursor: pointer !important;
        background-color: $color_10 !important;
        .text-decoration {
          text-decoration: underline;
        }
      }

      &:last-child {
        border-bottom: unset;
      }

      .bix-drop-item {
        display: flex;

        &-image {
          border: 1px solid transparent;
          overflow: hidden;
          border-radius: 4px;
          margin-right: 8px;

          img {
            width: 64px;
            height: 64px;
          }
        }

        &-info {
          flex: 1;

          &-title {
            font-size: 12px;
            font-weight: 600;
            color: #514b4d;
            line-height: 18px;
            margin-bottom: 4px;
            text-wrap: wrap;
          }

          &-sku {
            font-size: 12px;
            font-weight: 600;
            color: #514b4d;
            text-wrap: wrap;
            line-height: 18px;
          }

          &-date {
            font-size: 12px;
            font-weight: 500;
            color: #7e7e7e;
            line-height: 18px;
          }
        }

        &-status {
          align-self: center;
        }
      }
    }
  }

  .biz-drop::-webkit-scrollbar {
    display: none;
  }
}

.dropdown-menu-biz::-webkit-scrollbar {
  display: none;
}
