@import './styles/index.scss';
@import './styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body {
  margin: 0;
  font-family: $font_primary !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: $font_primary;
  font-weight: 500;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cursor-pointer {
  cursor: pointer;
}
#root > div.ant-layout {
  height: 100vh;
  margin-top: -2px;
  // height: calc(100vh - 58px) !important;
}

.ant-layout {
  background-color: #ffff;

  .ant-menu-item {
    &:active {
      background-color: unset !important;
    }
  }

  .collapse-button {
    position: absolute;
    top: calc(100% / 2 - 16px);
    right: 0;
  }

  .layout-sider-small {
    min-width: 52px !important;
    max-width: 52px !important;
    width: 52px !important;
    background-color: $color_90 !important;

    .ant-custom-menu {
      display: none;
    }

    .menu-icon-small {
      gap: 16px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }

  .layout-sider-default {
    min-width: 128px !important;
    max-width: 128px !important;
    width: 128px !important;
    background-color: $color_90 !important;

    .menu-icon-small {
      svg {
        display: none !important;
      }
    }
  }
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-input,
.ant-btn {
  font-family: $font_primary !important;
}

img {
  object-fit: contain;
}
