@import 'react-toastify/dist/ReactToastify.css';
@import '../../styles/variables.scss';

.Toastify__toast-container {
  min-width: 640px;
  min-height: 50px;
  border-radius: 4px;
  top: 12px;

  .toast__notification {
    &-error {
      background-color: #ffe0e0;
      color: #f43258;
      font-weight: 500;
      font-size: 14px;
    }

    &-success {
      background-color: $color_20;
      color: $primary_color;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .Toastify__close-button {
    display: flex;
    width: 20px;
    height: 21px;
    justify-content: center;
    align-items: center;
    gap: 8.333px;
    flex-shrink: 0;
    color: #514b4d;
    opacity: 1;
  }
}

.Toastify__toast {
  padding: 14px 16px !important;
}

.Toastify__close-button--light {
  margin: auto;
}
