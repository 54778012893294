.menu-profile {
  z-index: 10;
  display: flex;
  width: 40px;
  align-items: center;
  margin-right: 32px;
  margin-left: 0 !important;

  &.menu-setting {
    margin-right: 0;
  }

  &.active {
    .custom-avatar {
      border-color: #fff;
    }
  }
  .ant-menu {
    background-color: unset !important;

    &-inline {
      border-inline-end: none !important;
    }
    .ant-menu-title-content {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #514b4d;
    }

    .ant-menu-submenu-title {
      height: 52px !important;
      padding: 0 !important;
      margin: 0 !important;

      &:hover {
        background-color: unset;
      }
      .ant-menu-title-content {
        padding-left: 12px;
      }
    }

    .ant-menu-item {
      padding: 0 !important;
      margin: 0 !important;
      height: 44px !important;
      width: 100% !important;
      .ant-menu-title-content {
        padding: 6.5px 8px 6.5px 12px;
      }
    }
    .ant-menu-item:hover {
      background-color: #f2f2f2 !important;
      border-radius: 4px;
    }
  }
  .ant-menu-sub {
    padding-left: 20px !important;
  }
  .ant-menu-sub-item {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    .ant-menu-sub-item-des {
      font-size: 10px;
      font-weight: 500;
      line-height: 16px;
      text-align: left;
      color: #514b4d;
    }

    .ant-menu-sub-item-text {
      line-height: 22px;
    }
  }

  .logout {
    color: #f43258;
  }
  .ant-menu .ant-menu-item-disabled {
    display: none !important;
  }
}

.ant-menu-submenu-title:active {
  background-color: unset !important;
}

.menu-profile > .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-title-content {
  padding: 0 !important;
}
.menu-profile > .ant-menu > .ant-menu-submenu > .ant-menu {
  position: fixed;
  top: 54px;
  right: 32px;
  min-width: 280px;
  padding: 16px 8px !important;
  border-radius: 8px;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 10px 0px #00000040;
}
.menu-setting > .ant-menu > .ant-menu-submenu > .ant-menu {
  position: fixed;
  top: 54px;
  right: 241px;
  min-width: 280px;
  padding: 16px 8px !important;
  border-radius: 8px;
  background-color: #ffffff !important;
  box-shadow: 0px 5px 10px 0px #00000040;
}
.menu-profile > .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title {
  margin: 0 !important;
  padding: 0 !important;
  width: fit-content;
}
.menu-profile > .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  display: none;
}

.menu-profile > .ant-menu > .ant-menu-submenu-inline > .ant-menu-submenu-title > .menu-arrow-icon {
  display: none;
}

.ant-menu {
  overflow: unset !important;
}
