@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

.font-base {
  font-family: $font_primary;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.a-normal {
  text-decoration: none;
  color: #dcdcdc;
}

.a-normal:hover {
  color: #dcdcdc;
}

.text-pre-line {
  white-space: pre-line;
}

@for $i from 1 through 2 {
  .text-#{$i}-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: #{$i};
    line-clamp: #{$i};
    -webkit-box-orient: vertical;
  }
}

@for $i from 10 through 48 {
  .font-size-#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 0 through 700 {
  .height-#{$i} {
    height: #{$i}px !important;
  }
  .width-#{$i} {
    width: #{$i}px !important;
  }
}

@for $i from 0 through 100 {
  .m-t-#{$i} {
    margin-top: #{$i}px !important;
  }
  .m-r-#{$i} {
    margin-right: #{$i}px !important;
  }
  .m-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .m-l-#{$i} {
    margin-left: #{$i}px !important;
  }
  .m-#{$i} {
    margin: #{$i}px !important;
  }
  .m-t-b-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }
  .m-l-r-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }
}

@for $i from 0 through 100 {
  .gap-#{$i} {
    gap: #{$i}px !important;
  }
  .p-t-#{$i} {
    padding-top: #{$i}px !important;
  }
  .p-r-#{$i} {
    padding-right: #{$i}px !important;
  }
  .p-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .p-t-b-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }
  .p-l-r-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }
  .p-l-#{$i} {
    padding-left: #{$i}px !important;
  }
  .p-#{$i} {
    padding: #{$i}px !important;
  }
}

@for $i from 0 through 360 {
  .rotate-#{$i} {
    transform: rotate(#{$i}deg);
  }
}

.ebay__scroll-bar-custom-horizon {
  .ant-table-content {
    scrollbar-color: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    &::-webkit-scrollbar-corner,
    &::-webkit-scrollbar-track {
      background-color: #dcdcdc;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #adadad;
      background-clip: padding-box;
      border: 2px solid transparent;
      border-radius: 12px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgb(112, 112, 112);
    }

    &::-webkit-scrollbar-thumb:active {
      background-color: rgb(128, 128, 128);
    }

    /* Buttons */
    &::-webkit-scrollbar-button:single-button {
      background-color: #dcdcdc;
      display: block;
      background-size: 8px;
      background-repeat: no-repeat;
    }

    /* Up */
    &::-webkit-scrollbar-button:single-button:vertical:decrement {
      height: 10px;
      width: 10px;
      background-position: center 4px;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:vertical:decrement:active {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
    }

    /* Down */
    &::-webkit-scrollbar-button:single-button:vertical:increment {
      height: 10px;
      width: 10px;
      background-position: center 2px;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:vertical:increment:hover {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
    }

    ::-webkit-scrollbar-button:single-button:vertical:increment:active {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
    }

    /* Left */
    &::-webkit-scrollbar-button:single-button:horizontal:decrement {
      height: 7px;
      width: 14px;
      background-position: 5px 1px;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
    }

    /* Right */
    &::-webkit-scrollbar-button:single-button:horizontal:increment {
      height: 7px;
      width: 17px;
      background-position: 5px 1px;
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
    }

    &::-webkit-scrollbar-button:single-button:horizontal:increment:active {
      background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
    }
  }
}

.ebay__scroll-bar-custom {
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-corner,
  &::-webkit-scrollbar-track {
    background-color: #dcdcdc;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #adadad;
    background-clip: padding-box;
    border: 2px solid transparent;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(112, 112, 112);
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: rgb(128, 128, 128);
  }

  /* Buttons */
  &::-webkit-scrollbar-button:single-button {
    background-color: #dcdcdc;
    display: block;
    background-size: 8px;
    background-repeat: no-repeat;
  }

  /* Up */
  &::-webkit-scrollbar-button:single-button:vertical:decrement {
    height: 10px;
    width: 10px;
    background-position: center 4px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:vertical:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
  }

  /* Down */
  &::-webkit-scrollbar-button:single-button:vertical:increment {
    height: 10px;
    width: 10px;
    background-position: center 2px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:vertical:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  ::-webkit-scrollbar-button:single-button:vertical:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
  }

  /* Left */
  &::-webkit-scrollbar-button:single-button:horizontal:decrement {
    height: 7px;
    width: 14px;
    background-position: 5px 1px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
  }

  /* Right */
  &::-webkit-scrollbar-button:single-button:horizontal:increment {
    height: 7px;
    width: 17px;
    background-position: 5px 1px;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
  }

  &::-webkit-scrollbar-button:single-button:horizontal:increment:active {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
  }

  .ant-table-container {
    .ant-table-body {
      scrollbar-color: auto;

      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }

      &::-webkit-scrollbar-corner,
      &::-webkit-scrollbar-track {
        background-color: #dcdcdc;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #adadad;
        background-clip: padding-box;
        border: 2px solid transparent;
        border-radius: 12px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: rgb(112, 112, 112);
      }

      &::-webkit-scrollbar-thumb:active {
        background-color: rgb(128, 128, 128);
      }

      /* Buttons */
      &::-webkit-scrollbar-button:single-button {
        background-color: #dcdcdc;
        display: block;
        background-size: 8px;
        background-repeat: no-repeat;
      }

      /* Up */
      &::-webkit-scrollbar-button:single-button:vertical:decrement {
        height: 10px;
        width: 10px;
        background-position: center 4px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='50,00 0,50 100,50'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='50,00 0,50 100,50'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:vertical:decrement:active {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='50,00 0,50 100,50'/></svg>");
      }

      /* Down */
      &::-webkit-scrollbar-button:single-button:vertical:increment {
        height: 10px;
        width: 10px;
        background-position: center 2px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 100,0 50,50'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:vertical:increment:hover {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 100,0 50,50'/></svg>");
      }

      ::-webkit-scrollbar-button:single-button:vertical:increment:active {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 100,0 50,50'/></svg>");
      }

      /* Left */
      &::-webkit-scrollbar-button:single-button:horizontal:decrement {
        height: 7px;
        width: 14px;
        background-position: 5px 1px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,50 50,100 50,0'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,50 50,100 50,0'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,50 50,100 50,0'/></svg>");
      }

      /* Right */
      &::-webkit-scrollbar-button:single-button:horizontal:increment {
        height: 7px;
        width: 17px;
        background-position: 5px 1px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(96, 96, 96)'><polygon points='0,0 0,100 50,50'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(112, 112, 112)'><polygon points='0,0 0,100 50,50'/></svg>");
      }

      &::-webkit-scrollbar-button:single-button:horizontal:increment:active {
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(128, 128, 128)'><polygon points='0,0 0,100 50,50'/></svg>");
      }
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ant-spin-dot-holder {
  color: $primary_color;
}

.ant-spin-dot-item {
  color: $primary_color;
}

.ant-input-outlined:hover,
.ant-input-outlined:focus-within,
.ant-input-outlined:focus,
.ant-btn.ant-btn-default:not(:disabled):hover {
  border-color: $primary_color;
  box-shadow: none;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  box-shadow: none !important;
}
.ant-btn-primary:hover {
  background: #f8af79 !important;
  border-color: #f8af79 !important;
}
