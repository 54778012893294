.color-primary {
  color: $primary_color !important;
}

.color-title {
  color: $text_title_color !important;
}

.color-sub-title {
  color: $text_title_sub_color !important;
}

.color-cancel {
  color: $bg_cancel !important;
}

.color-sub-cancel {
  color: $color_sub_cancel !important;
}

.color-white {
  color: #ffffff !important;
}

.bg-primary {
  background-color: $primary_color !important;
}

.bg-cus-primary {
  background-color: $primary_color !important;
}

.bg-cancel {
  background-color: $bg_cancel !important;
}

.bg-second {
  background-color: $bg_second !important;
}

.bg-primary-90 {
  background-color: #f47920;
}
