@import '../../styles/variables.scss';
.dowload-ebay-button {
  display: flex;
  align-items: center;
  padding: 0 19px;
  border: 2px solid $primary_color;
}

.dowload-button {
  display: flex;
  align-items: center;
  padding: 0;
  border: 2px solid $primary_color;
}

.custom-ant-button {
  min-width: 148px;
  height: 44px;
  border-radius: 8px;
}
.custom-ant-button:hover {
  background: $color_60 !important;
  border-color: $color_60 !important;
}

.ant-btn-primary:disabled {
  cursor: not-allowed;
  border-color: #dcdcdc !important;
  background: #dcdcdc !important;

  color: #9d9d9d;

  text-align: center;
  font-style: normal;
  span {
    color: #9d9d9d;
  }
}

.button-detail {
  &__container {
    width: 76px;
    height: 32px;
    border-radius: 16px;
    border: 1px solid $primary_color;
    align-items: unset;
    //justify-content: space-between;
    svg {
      rect {
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      path {
        transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
    }
    &:hover {
      background: $primary_color !important;
      p {
        color: #ffffff;
      }
      svg {
        rect {
          fill: #ffffff;
          stroke: #ffffff;
        }
        path {
          stroke: $primary_color;
        }
      }
    }
  }

  &__content {
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    margin-bottom: 0 !important;
    color: $primary_color;
    white-space: pre-line;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
