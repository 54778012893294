@import '../../styles/variables.scss';
.ant-custom-select {
  width: 400px;
  height: 40px;
  border-radius: 8px;

  .ant-select-selector {
    border-radius: 8px;
    border: 1px solid #adadad !important;

    &:hover {
      border-color: $primary_color !important;
    }
  }

  .ant-select-item-option {
    height: 32px;
    padding: 0 12px;
  }

  .ant-select-dropdown {
    padding-left: 0px;
    padding-right: 0px;
    border-radius: 0px 0px 8px 8px;
    inset: auto !important;
  }

  .ant-select-item-option:hover {
    background: #f2f2f2 !important;
  }

  .ant-select-item-option-selected {
    background-color: unset !important;

    .sub-icon {
      display: block !important;
    }
  }
}
